<template>
    <div id="billing-documents">

        <b-overlay :show="showLoading" spinner-variant="primary">

            <section id="ecommerce-header">

                <b-row>
                    <b-col cols="2">
                        <div class="ecommerce-header-items">
                            <div class="result-toggler">
                                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                                    @click="mqShallShowLeftSidebar = true" />
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <h2 class="d-block d-lg-none"> Documents de facturation</h2>
                    </b-col>

                </b-row>
            </section>


            <b-card>

                <ag-grid :filterComponentData="filterComponentData" :agGrid="agGrid"
                    :pageTitle="isMobileDevice() ? '' : 'Documents de facturation'" @refresh="loadData"
                    @filterClicked="filterClicked" />

            </b-card>

            <!-- Sidebar -->
            <portal to="content-renderer-sidebar-detached-left">
                <billing-docs-left-filter-sidebar :filters="filters" :filter-options="filterOptions"
                    :showLoading="showLoading" :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
                    @triggerFilter="handleEventFilter" />
            </portal>

        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
// import BillingDocsFilter from '@/views/customer/docs/BillingDocsFilter.vue';
import BillingDocsLeftFilterSidebar from "./BillingDocsLeftFilterSidebar.vue";
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

export default {
    components: {
        // VueAutosuggest,
        AgGrid,
        BillingDocsLeftFilterSidebar
    },
    setup() {
        const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
        return {
            mqShallShowLeftSidebar,
        }
    },
    data() {
        return {
            allData: [],
            filters: {
                month: '',
            },
            filterOptions: {
                months: [
                ],
            },
            filterComponentData: {},
            // BillingDocsFilter: BillingDocsFilter,
            selectedMonth: "",
            agGrid: {
                columnDefs: [{
                    headerName: "Mois",
                    field: "Month",
                },
                {
                    headerName: "Document",
                    field: "Description",
                },
                {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    headerName: '',
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'buttonCellRenderer',
                    cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                    width: 80
                },
                ],
                rows: []
            },
            fields: [{
                label: 'Code Article',
                key: 'No',
                sortable: true
            },
            {
                label: 'Désignation article',
                key: 'Description',
                sortable: true
            },
            {
                label: 'Prix',
                key: 'Unit_Price',
                sortable: true
            }, {
                label: 'Quantité',
                key: 'Quantity',
                sortable: true
            }
            ],
            showLoading: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        // // Get the current date
        // const currentDate = new Date();
        // // Create an array to store the last 10 months
        // const last10Months = [];
        // // Define an array to map month numbers to month names
        // const monthNames = [
        //     "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        //     "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"
        // ];
        // // Loop to get the last 10 months
        // for (let i = 0; i < 12; i++) {
        //     // Calculate the year and month for the current iteration
        //     const year = currentDate.getFullYear();
        //     const month = currentDate.getMonth();
        //     // Format the month and add it to the list
        //     const formattedMonth = `${monthNames[month]} ${year}`;
        //     last10Months.push(formattedMonth);
        //     // Move to the previous month
        //     currentDate.setMonth(month - 1);
        // }
        // // Output the list of the last 10 months
        // console.log(last10Months);
        // this.selectedMonth = last10Months[0];
        // this.filterComponentData["months"] = last10Months;
        // this.filterOptions.months = last10Months;
        // this.filters.month = last10Months[0];
        this.loadData();
        // this.showLoading = false;
        // this.loadData();
    },
    methods: {
        isMobileDevice() {
            return window.innerWidth <= 769;
        },
        async handleEventFilter(data) {
            this.mqShallShowLeftSidebar = false;
            this.showLoading = true;
            if (data == "Tous") {
                this.agGrid.rows = this.allData;
            }
            else {
                this.agGrid.rows = this.allData.filter(item => item.Month === data);
            }
            this.showLoading = false;
        },
        async printDoc(event) {
            this.showLoading = true;
            const inputString = event.Path;
            let searchString = "D:\\Documents_Portail\\";
            let startIndex = inputString.indexOf(searchString);
            if (startIndex == -1) {
                searchString = "H:\\Documents_Portail\\";
                startIndex = inputString.indexOf(searchString);
            }
            const encodedCode = encodeURIComponent(inputString.substring(startIndex + searchString.length));
            const decodedString = decodeURIComponent(encodedCode);
            const convertedString = decodedString.replace(/\\/g, '//');
            let response = await this.$http.get("file/document?code=" + convertedString, { responseType: "blob" }).catch(() => this.showLoading = false);
            this.showLoading = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            URL.revokeObjectURL(fileURL);
        },
        filterClicked(obj) {
            // console.log(obj);
            this.selectedMonth = obj;
            this.loadData();
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.post("billing_document/findWhere", [
            ]).catch(() => this.showLoading = false);
            const uniqueMonths = new Set(response.data.map(item => item.Month));
            // Convert the Set back to an array if needed
            const distinctMonths = Array.from(uniqueMonths);
            distinctMonths.unshift("Tous");
            this.filters.month = "Tous";
            this.filterOptions.months = distinctMonths;
            this.agGrid.rows = response.data;
            this.allData = response.data;
            this.showLoading = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>